import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "lobby fill-height d-flex flex-column" }
const _hoisted_2 = { class: "d-flex align-center justify-space-between pa-4 bg-active" }
const _hoisted_3 = { class: "d-flex gap-2" }
const _hoisted_4 = { class: "text-title-1" }
const _hoisted_5 = { class: "text-title-2" }
const _hoisted_6 = {
  key: 0,
  class: "ms-1"
}
const _hoisted_7 = { class: "text-subtitle-1" }
const _hoisted_8 = { class: "lobby__now font-weight-600" }
const _hoisted_9 = { class: "overflow-y-auto flex-grow-1" }
const _hoisted_10 = { class: "d-flex flex-column" }
const _hoisted_11 = { class: "row-flex-4" }
const _hoisted_12 = { class: "d-flex flex-column fill-height vh-65" }
const _hoisted_13 = { class: "text-title-1" }
const _hoisted_14 = { class: "d-flex flex-column fill-height vh-65" }
const _hoisted_15 = { class: "text-title-1" }
const _hoisted_16 = { class: "d-flex flex-column fill-height vh-65" }
const _hoisted_17 = { class: "text-title-1" }
const _hoisted_18 = {
  key: 0,
  class: "pa-4"
}
const _hoisted_19 = { class: "text-title-1" }

import { defineAsyncComponent, onMounted, ref } from 'vue';
import { useAuthUser } from '@/views/employee/composables/use-auth-user';
import UpcomingEvents from '@/views/calendar/views/upcoming-events.view.vue';
import { authCheck } from '@/store/auth/helpers';
import Modules from '@/router/constants/modules';
import { useLobbyStore } from '@/views/lobby/store';
import { GET_UPCOMING_EVENTS } from '@/views/calendar/store/calendar.constants';
import { GET_NOTIFICATIONS } from '@/views/notifications/store/notification.constants';
import dayjs from 'dayjs';
import { GlobalVariables } from '@shared/constants';


export default /*@__PURE__*/_defineComponent({
  __name: 'lobby.view',
  setup(__props) {

const Notifications = defineAsyncComponent(() => import(/* webpackChunkName: "lobby" */ '@/views/notifications/components/notifications-tabs.vue'));
const CalendarLobby = defineAsyncComponent(() => import(/* webpackChunkName: "lobby" */ '@/views/lobby/components/calendar-lobby.vue'));
const JobOffer = defineAsyncComponent(() => import(/* webpackChunkName: "lobby" */ '@/views/job/views/offer/components/job-offer-search.vue'));

const { activeUser } = useAuthUser();
const now = ref<string>(dayjs().format(GlobalVariables.FULL_DATE_AND_TIME));

const { [GET_UPCOMING_EVENTS]: getUpcomingEvents, [GET_NOTIFICATIONS]: getNotifications } = useLobbyStore();

onMounted(() => {
  setInterval(() => {
    now.value = dayjs().format(GlobalVariables.FULL_DATE_AND_TIME);
  }, 1000);
});

return (_ctx: any,_cache: any) => {
  const _component_vz_avatar = _resolveComponent("vz-avatar")!
  const _component_vz_divider = _resolveComponent("vz-divider")!
  const _component_vz_card = _resolveComponent("vz-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_vz_avatar, {
          hid: _unref(activeUser)?._id,
          "first-name": _unref(activeUser)?.firstName,
          "last-name": _unref(activeUser)?.lastName,
          size: 70
        }, null, 8, ["hid", "first-name", "last-name"]),
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('GENERAL.WELCOME')), 1),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", null, _toDisplayString(_unref(activeUser)?.firstName) + " " + _toDisplayString(_unref(activeUser)?.lastName), 1),
            (_unref(activeUser)?.nickName)
              ? (_openBlock(), _createElementBlock("span", _hoisted_6, "(" + _toDisplayString(_unref(activeUser)?.nickName) + ")", 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_7, _toDisplayString(_unref(activeUser)?.company), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(dayjs)().format(_unref(GlobalVariables).FULL_DATE_AND_TIME)), 1)
    ]),
    _createVNode(_component_vz_divider),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.$t('MODULE.UPCOMING_EVENTS')), 1),
            _createVNode(_component_vz_card, { class: "flex-grow-1 overflow-hidden" }, {
              default: _withCtx(() => [
                _createVNode(UpcomingEvents, {
                  disabled: "",
                  callback: _unref(getUpcomingEvents)
                }, null, 8, ["callback"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.$t('MODULE.CALENDAR')), 1),
            _createVNode(_component_vz_card, { class: "flex-grow-1" }, {
              default: _withCtx(() => [
                _createVNode(_unref(CalendarLobby))
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.$t('MODULE.NOTIFICATIONS')), 1),
            _createVNode(_component_vz_card, { class: "flex-grow-1 overflow-hidden" }, {
              default: _withCtx(() => [
                _createVNode(_unref(Notifications), {
                  disabled: "",
                  callback: _unref(getNotifications)
                }, null, 8, ["callback"])
              ]),
              _: 1
            })
          ])
        ]),
        (_unref(authCheck)(_unref(Modules).FREELANCE.JOB_OFFER))
          ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
              _createElementVNode("p", _hoisted_19, _toDisplayString(_ctx.$t('MODULE.JOB_OFFER')), 1),
              _createVNode(_component_vz_card, null, {
                default: _withCtx(() => [
                  _createVNode(_unref(JobOffer), { minimal: "" })
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})